<template>
  <div>
    <aside>
      <div class="user-content">
        <p>
          欢迎您使用玩呗！为使用玩呗服务，您应当阅读（未成年人应当在监护人陪同下阅读）并遵守《玩呗用户服务协议》（以下简称“本协议”）。请您务必审慎阅读、全面理解各条款内容，<b>尤其是开通或使用产品内某项特定服务时，请在独立思考的基础上选择接受或不接受。</b>如您不同意本协议任意内容，请不要注册或使用玩呗服务。如您通过进入注册程序并勾选“我已经同意用户协议”，即表示您与广州游盟信息技术有限公司 （以下简称“游盟”）已达成协议，自愿接受本协议的所有内容。此后，您不得以未阅读、本协议内容作任何形式的抗辩。
        </p>
        <br />
        <br />
        <p>
          青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
        </p>
        <br />
        <br />
        <h4>一、账号注册及个人信息保护</h4>
        <br />
        <span>
          1.
          您在注册过程中，需要填写一些必要的信息，<b>因国家法律法规的特殊规定，您需要填写真实的身份信息，</b>请保持这些信息的及时更新，以便游盟更好地向您提供服务。若您填写的信息不完整或不准确，将可能无法使用服务或在使用服务的过程中受到限制。您充分理解并同意：您须对在玩呗上的注册信息的真实性、合法性、有效性承担全部责任，您不得冒充他人或利用他人的名义传播任何信息，不得恶意使用注册帐号导致其他用户误认。否则，玩呗有权立即停止提供服务，注销您的玩呗帐号，您应自行承担由此而产生的一切法律责任。
        </span>
        <br />
        <span>
          <b
            >2.
            您注册成功后，您所申请注册成功的玩呗帐号与密码由您自身保管，您在使用玩呗服务过程中，须对自身使用玩呗服务的行为承担责任。对任何由您通过玩呗服务发布、公开的信息，及对由此产生的任何后果承担全部责任。</b
          >
        </span>
        <br />
        <span>
          3.
          游盟与您一同致力于个人信息的保护，保护用户个人信息是游盟的一项基本政策。未经您的同意，游盟不会向游盟以外的任何公司（关联公司除外）、组织和个人泄露您的个人信息，但法律法规另有规定的除外。
          <br />
          4.
          您理解并同意：鉴于玩呗提供的服务包含网络论坛服务，为改善用户体验，游盟可以：
          <br />
          (1)
          基于用户的操作行为对用户数据进行调查研究和分析，从而进一步优化玩呗服务。
          <br />
          (2)
          对您在本服务中的昵称、头像等相关操作信息进行使用，向您本人、其他用户展示。
          <br />
          5.
          鉴于玩呗提供的服务包含网络论坛服务，为改善用户体验，游盟可能会将您的个人信息公开范围默认设置为公开，该设置会导致他人接触或获取您的个人信息，请您根据需要，及时在“我-设置-个人资料”予以设置变更。
          <br />
          6.
          您应对通过本协议了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，<b
            >您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。</b
          >
          <br />
          7. 您了解并同意玩呗的帐号注册与帐号信息修改说明
          <br />
          (1)
          注册账号：下载玩呗app—点击右下角【我的】—立即登录—注册—手机或者用户名注册和密码、第三方登录（QQ、微信、微博）—手机验证码验证—注册成功。
          <br />
          (2)
          安全信息修改：修改玩呗登录密码，进入界面—点击右下角【我的】—点击右上角【设置】—密码设置—修改密码—选择安全验证号码—获取验证码—输入新密码—确定修改成功—重新登录
          <br />
          (3)
          个人信息修改：进入界面—点击右下角【我的】—点击编辑个人资料—修改头像/修改昵称/设置性别
          <br />
          (4) 注销帐号：如需要注销帐号请联系客服guildunion@163.com
          <br />
          8.
          <b
            >玩呗帐号的所有权归玩呗所有，您完成申请注册手续后，获得玩呗帐号的使用权，该使用权仅属于初始申请注册人，禁止用户赠与、借用、租用、转让或售卖玩呗帐号。如果使用者并非帐号初始申请注册人，玩呗有权在未经通知的情况下冻结、回收该帐号而无需向该帐号使用人承担法律责任，由此导致的包括并用户资料清空等损失由用户自行承担。</b
          >若帐号的归属出现争议的，玩呗在收到相关方的投诉后，有权暂时冻结该争议帐号；争议各方在举证期（7个工作日）内提供证据证明帐号归属，玩呗依据各方提供的证据判断归属后，解冻争议帐号。
        </span>
        <br />
        <br />
        <h4>二、服务内容</h4>
        <br />
        <span>
          1.
          玩呗服务的具体内容由游盟根据实际情况提供，包括但不限于授权用户通过其帐号，使用玩呗获取玩呗内所自带的或与第三方公司合作推荐的游戏、应用，发布帖子、观点、评论、图片、视频等，参与玩呗中的活动，兑换实物商品或虚拟货币、礼包等商品。游盟有权对其提供的服务或产品形态进行升级或其他调整，并将及时更新页面告知用户。
          <br />
          2.
          玩呗服务可能包含第三方提供的产品或服务。当您使用第三方提供的产品或服务时，可能会另有相关的协议或规则，您同样应当认真阅读并遵守。如您在使用第三方产品或服务时发生任何纠纷的，请您与第三方直接联系，游盟不承担任何责任，但根据需要会依法提供必要的协助。用户理解，游盟仅提供与玩呗服务相关的技术服务等，除此之外与相关网络有关的设备（包括但不限于个人电脑、手机、及其他与接入互联网或移动网相关的装置等）及所需的费用（包括但不限于为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费等）均应由用户自行负担。
          <br />
          3.
          本服务仅供您个人以非商业目的使用，您不得进行对其他用户恶意推广、骚扰等超出正常好友沟通、交流范围的行为，否则由此造成游盟的任何损害结果，均由您承担赔偿责任。
          <br />
        </span>
        <br />
        <br />
        <h4>三、用户发布的内容</h4>
        <br />
        <span>
          1.
          您可以在本服务平台上发布和共享信息，但您应当保证拥有这些信息的合法权利或已经得到权利人的授权。
          <br />
          2.
          在本服务中，鉴于服务的社交服务特性及用户的社交需求，您所发布的内容默认为允许评论、回复等，即表明您同意向他人公布并许可他人对您所发布的内容有评论与传播的权利。
          <br />
          3.
          用户同意授权玩呗永久无偿使用通过玩呗平台发布的或用户使用玩呗服务过程中形成的信息、内容等，玩呗可以通过包括改编、汇编、复制、发行、网络传播在内的一切方式进行使用。如果用户不同意玩呗使用相关内容，应事先通过书面方式告知玩呗。
          <br />
          4.
          您充分理解并同意：玩呗仅为用户提供信息存储、分享平台服务，用户必须为自己注册帐号下发生一切行为负责，包括因您所传送至玩呗平台的任何内容、信息等所导致的不利后果，该等不利后果包括但不限于赔偿、罚款、司法／仲裁程序费用、律师费、合理支出、给玩呗造成的损害等。用户应对玩呗平台上其他注册用户发布的内容自行加以判断并承担因使用该内容而给自己、他人及社会造成的法律责任，包括但不限于因对内容的准确性、真实性、完整性或实用性等的依赖而产生的风险。
          <br />
          5.
          用户在使用玩呗服务中自行发布的任何内容、信息等并不反映或代表玩呗的观点、立场或政策，玩呗对此不承担任何责任。玩呗对用户上传的所有信息的真实性、合法性、无害性、有效性等不负任何单独或连带保证责任。用户应自行承担其所传播信息而引发的相关法律责任。
          <br />
        </span>
        <br />
        <br />
        <h4>四、用户行为规范</h4>
        <br />
        <span>
          <b>
            1.
            您在玩呗上发布信息或者利用玩呗的服务时必须符合中国有关法规，不得在玩呗上或者利用玩呗的服务制作、复制、发布、传播以下信息：<br />
            (1) 违反宪法确定的基本原则的；<br />
            (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
            (3) 损害国家荣誉和利益的；<br />
            (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br />
            (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
            (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
            (7) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<br />
            (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br />
            (9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br />
            (10) 以非法民间组织名义活动的；<br />
            (11) 含有法律、行政法规禁止的其他内容的。<br />
          </b>
          2.
          您在玩呗上发布信息或者利用玩呗的服务时还必须符合其他有关国家和地区的法律规定以及国际法的有关规定。您需遵守法律法规的规定使用玩呗服务。
          <br />
          3. 您不得利用玩呗的服务从事以下活动：<br />
          (1) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；<br />
          (2) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；<br />
          (3)
          未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br />
          (4) 故意制作、传播计算机病毒等破坏性程序的；<br />
          (5)
          任何对玩呗服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等行为；<br />
          (6) 其他危害计算机信息网络安全的行为。<br />
          <b>
            4. 您不得以任何方式干扰玩呗的服务。<br />
            5.
            您不得通过任何渠道以任何方式交易玩呗帐号，若有相关行为，游盟有权收回帐号使用权或注销帐号。<br />
          </b>
          6.
          您应遵守游盟的所有其他规定和程序。您须对自己在使用玩呗服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在游盟首先承担了因您行为导致的行政处罚或侵权损害赔偿责任后，您应给予游盟等额的赔偿。您理解，如果游盟发现其传输的信息明显属于4.1条所列内容之一的，依据中国法律，游盟有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。<br />
        </span>
        <br />
        <br />
        <h4>五、用户权利保障</h4>
        <br />
        <span>
          1. 您同意保障和维护游盟及其他全体用户的利益。<b
            >您或者使用您注册帐号的其他人在进行游戏过程中侵犯第三方知识产权及其他权利而导致被侵权人索赔的，由账号注册人承担责任。如您或其它网络服务提供者利用游盟的服务侵害他人民事权益的，应当承担侵权等法律责任。
            <br />
            2.
            根据合理判断，玩呗可以对违反法律法规、本协议约定、侵犯、妨害、威胁他人权利的内容，或者假冒他人名义发布的内容，依法采取停止传输、下线等措施，并有权依合理判断对违反本条款的用户采取适当的法律行动，包括但不限于：从玩呗服务平台中保全具有违法性、侵权性、不当性等内容、限制或禁止用户使用玩呗全部或部分服务、注销用户帐户以及依据法律法规保存有关信息并向有关部门报告等。</b
          >
          <br />
          3.
          若您在使用玩呗提供的服务过程中，发现涉政、涉枪、涉毒、涉暴、赌博、涉黄的内容，请向玩呗工作人员举报、投诉。玩呗在收到举报、投诉后，将派专员及时处理。<br />
        </span>
        <br />
        <br />
        <h4>六、结束服务</h4>
        <br />
        <span>
          1.
          您或游盟可随时根据实际情况终止使用/提供服务。游盟有权单方不经通知终止向用户提供某一项或多项服务；您有权单方不经通知单方终止接受游盟的服务。<br />
          2.
          结束用户服务后，您使用玩呗服务的权利立即终止。从那时起，游盟不再对用户承担任何义务。<b
            >您知道并同意，服务变更、中止与结束属于游盟商业决策之内容。您不得因服务的变更、中止或者结束而要求游盟继续提供服务或者承担任何形式的赔偿责任等。</b
          >
          <br />
        </span>
        <br />
        <br />
        <h4>七、内容的所有权及知识产权</h4>
        <br />
        <span>
          <b>
            1.
            所有用户在使用玩呗服务时发表上传的文字等用户原创的信息知识产权归用户所有。但用户的发表、上传行为是对玩呗服务平台的授权，用户确认其发表、上传的信息非独占性、永久性的免费授权给玩呗，玩呗可将前述信息在玩呗旗下或关联企业的服务平台上使用，可再次编辑后使用，也可以由玩呗授权给合作方使用。如玩呗使用前述搜权内容而产生收益的，亦与用户无关。
          </b>
          <br />
          2.
          玩呗对以下自行制作内容享全部知识产权：玩呗网站、手机应用、软件、程序代码以及内含的文字、图片、视频、音频等元素。
          <br />
          3.
          玩呗服务中所涉及的玩呗图形、文字或其他标志及产品、服务名称，均为玩呗之商业标识（以下简称“标识”）。未经游盟事先书面同意用户不得将玩呗标识以任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理玩呗标识的行为。用户由于非法使用玩呗标识给玩呗或他人造成损失的，游盟有权向用户追究相关法律责任。
          <br />
        </span>
        <br />
        <br />
        <h4>八、储存</h4>
        <br />
        <span>
          游盟对玩呗上所有服务将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任。另外游盟保留判定用户的行为是否符合本服务条款的要求的权利，如果用户违背了本服务条款的规定，游盟有权中止或者终止对其玩呗帐号的服务。
        </span>
        <br />
        <br />
        <h4>九、通知</h4>
        <br />
        <span>
          所有发给用户的通知都可通过系统通知、电子邮件、常规的信件或在手机应用或网站显著位置公告的方式进行传送。游盟将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更、或其它重要事情。
        </span>
        <br />
        <br />
        <h4>十、免责声明</h4>
        <br />
        <span>
          1.
          用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，玩呗及其合作单位不承担责任。<br />
          2.
          因技术故障等不可抗事件影晌到服务的正常运行的，玩呗及其合作单位承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，玩呗及其合作单位不承担责任。<br />
          3.
          您充分理解并同意：用户在使用服务过程中会将受到网络服务质量、社会环境、用户操作错误等诸多因素的影响，可能会受到各种问题的侵扰，比如被他人利用用户的资料，进行现实生活中的骚扰。用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影晌本服务的正常使用等等。用户应加强对信息安全及使用者资料的保护意识，以免遭受损失和骚扰，因自主使用玩呗服务的风险由用户自行承担。
          <br />
          4.
          您充分理解并同意：使用本服务过程中存在可能遭受来自他人的包括但不限于威胁性的、诽谤性的、令人反感的或其他侵犯他人权利（包括但不限于知识产权）的匿名或冒名侵害，用户须承担以上凤险。玩呗和其合作公司对玩呗平台服务不作任何明确或暗示的担保，包括但不限于有关信息真实性、适商性、适于某一特定用途、所有权和非侵权性的内容。由于部分用户的不正当或非法使用玩呗平台服务产生的直接、间接侵还第三人合法权利的法律责任，玩呗不承担任何责任。
          <br />
        </span>
        <br />
        <br />
        <h4>十一、其他</h4>
        <br />
        <span>
          1. 玩呗保留本协议解释和修改权利。 <br />
          2.
          游盟不行使、未能及时行使或者未充分行使本条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响游盟在将来行使该权利。<br />
          3. 在法律允许的最大范围内，游盟保留对本协议的最终解释权。<br />
          4.
          本协议解释、效力及纠纷的解决，适用中华人民共和国法律，由本协议签订地有管辖权法院管辖。<br />
          5. 本协议签署地点为广州市天河区。<br />
        </span>
        <br />
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'YbHomeUserserve'
}
</script>

<style lang="scss" scoped>
</style>
