<template>
  <div>
    <aside>
      <div class="user-content">
        <p>
          本《隐私政策》主要在<b
            >具体地阐述了我们如何收集和使用您的个人信息，并对其中涉及到的个人信息进行加粗处理。</b
          ><br />
          我们希望通过本隐私政策向您说明，在使用玩呗时，我们如何收集、使用、存储、分享和转让您的个人信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。请在使用务必仔细阅读并了解本隐私政策，在确认充分理解并同意后使用我们的产品或服务。一旦您开始使用玩呗，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、储存和分享您的相关信息。如对本政策内容有任何疑问、意见或建议，您可通过本隐私政策提供的各种联系方式与我们联系。
        </p>
        <br />
        <br />
        <h3 style="text-align: center">《玩呗隐私政策》正文</h3>
        <br />
        <span style="width: 100%; display: inline-block; text-align: end"
          >生效日期：2021年6月1日</span
        >
        <br />
        <span><b>序言</b></span>
        <p>
          玩呗是基于Android（安卓）系统、iOS（苹果）系统等设备（以下简称“手机”）的游戏资讯社区。玩呗由广州游盟信息技术有限公司 （以下简称“游盟”）提供。<br />
        </p>
        <p>
          非常感谢您对游盟的信任。游盟保证公司各产品（或服务）在使用您的个人信息的时候遵循“四不三必须”的七个规范，即：<br />
          一、不该看的不看。<br />
          二、不该传的不传。<br />
          三、不该存的不存。<br />
          四、不该用的不用。<br />
          五、一切行为必须明示，尊重用户的知情权和选择权。<br />
          六、必须经过用户许可。<br />
          七、必须对收集的用户隐私信息负责。<br />
        </p>
        <br />
        <span>
          <b>本隐私政策将帮助您了解以下内容：</b><br />
          一、相关定义 <br />
          二、我们如何收集和使用您的个人信息 <br />
          三、我们如何存储您的个人信息 <br />
          四、我们如何共享、转让、公开披露您的个人信息 <br />
          五、我们如何保护您的个人信息 <br />
          六、您管理个人信息的权利 <br />
          七、未成年人个人信息保护 <br />
          八、隐私政策的适用范围 <br />
          九、隐私政策的变更和修订 <br />
          十、如何联系我们 <br />
        </span>
        <br />
        <h4>一、相关定义</h4>
        <span>
          1.
          个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
          <br />
          <b
            >2.
            个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本隐私权政策中对个人敏感信息以粗体进行显著标识）。
          </b>
          <br />
          3.
          个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
          <br />
        </span>
        <br />
        <h4>二、我们如何收集和使用您的个人信息</h4>
        <span>
          您在使用玩呗时，我们需要/可能需要收集和使用您的一些个人信息，我们收集和使用的您的个人信息类型包括两种：<br />
          <b
            >第一种：玩呗产品与/或服务的核心业务功能所必需的信息：此类信息为产品与/或服务正常运行的必备信息，您须授权我们收集。如您拒绝提供，您将无法正常使用我们的产品或服务；<br />
            第二种：附加业务功能可能需要收集的信息：此信息为非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。</b
          ><br />
          <b>（一）内容资源下载功能 </b> <br />
          为实现手机内容资源下载的基本功能，玩呗会通过手机系统的公用接口收集<b>经过MD5算法加密的国际移动设备身份码（IMEI）和网络设备地址（MAC），以及手机型号、手机系统版本号、系统编号、系统ID号、屏幕分辨率、上网类型、手机中软件的名称、版本号、版本名、包名、软件使用时间和频率、软件崩溃信息与设备和软件相关的信息。</b>这些信息是提供服务所必须收集的基础信息，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。
          <br />
          <b>（二）软件管理功能 </b> <br />
          为实现手机软件下载、安装、升级、卸载的软件管理功能，在您使用玩呗时，我们会采集您手机中<b>已安装本产品的软件名称、版本号、版本名、软件包名信息</b>并上传到我们的服务器进行软件版本比对。如果经比对发现有更新的版本，玩呗会提示您升级相应的软件。我们收集这些信息是为了向您提供软件管理的相关服务，为实现这些功能所必需，不涉及您个人的敏感信息，也不包括您使用这些软件所产生的任何相关数据（例如软件的存档、文档、图片等），玩呗亦不会采集、上传或存储此类数据，或将其与您的其他任何个人信息进行匹配。
          <br />
          <b>（三）注册功能</b> <br />
          为实现注册游盟账号的功能，您需要按照我们的指引完成一系列注册的程序，在您成为注册用户后，我们将为您提供专属于注册用户的产品与/或服务。在此过程中，您需要提供给我们<b>手机号、用户名、密码，</b>收集这些信息是为了帮助您完成游盟账号注册，保护您的账号安全。与此同时，您也可以在注册时或后续使用过程中向我们提供您的<b>头像、昵称、性别、生日、地区，</b>这些信息有助于我们向您提供更加个性化的服务和推荐，但拒绝提供这些信息不影响您使用本款产品的其他功能。您亦可以通过“我-个人资料”界面更改这些信息。当您注册成功后，您所申请注册成功的帐号与密码由您自身保管，您在使用玩呗服务过程中，须对自身使用玩呗服务的行为负责，对任何由您通过玩呗服务发布、公开的信息，及对由此产生的任何后果承担全部责任。
          <br />
          <b>（四）搜索功能</b> <br />
          当您使用搜索功能时，我们需要收集您的一些信息，包括如下个人信息：<b>搜索的字词、浏览记录和时间、搜索的时间以及与它们互动的次数。</b>我们收集这些信息是为了向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的产品和服务。
          <br />
          <b>（五）玩呗交流</b> <br />
          玩呗是一个用户分享、交流、活动互动的平台。任何用户都可以通过登陆游盟用户中心登陆玩呗进行发帖、回帖操作。当您使用论坛的发帖和回帖操作时，根据国家相关法律规定，<b>我们可能需要收集您的手机号码，并将手机号码与游盟账号绑定进行身份认证。</b>但如果您拒绝进行身份认证，您仍然可以继续浏览玩呗。
          <br />
          <b>（六）个性化推荐/定向推送</b> <br />
          为了向您提供更优质和更适合您的产品与/或服务、让您有更好的产品与/或服务的使用体验或您同意的其他用途，在符合相关法律法规的前提下，我们可能将您授权提供给我们的或我们的某一项产品与/或服务所收集的您的个人信息，以汇集、分析或者个性化的方式单独或与其他信息结合进行使用，以便于向您提供个性化服务/定向推送。我们可能会收集您使用我们的产品与/或服务时的<b>搜索记录、IP地址、访问日期和时间、您的偏向网络行为、兴趣偏好等信息形成用户画像</b>用于帮助使我们更加了解您如何接入和使用我们的产品与/或服务、维护和改进我们的产品与/或服务或对您作出其他方面的回应。
          <br />
          <b>（七）从外部第三方间接收集的您的个人信息</b> <br />
          为方便您登录游盟账号，您可以通过<b>微博、QQ、微信等第三方账号登陆</b>本款产品及游盟的其他产品和服务。为实现前述目的，我们可能需要从第三方获取并收集您的第三方账号信息，如昵称、头像。除上述情况外，目前游盟不会主动从第三方获取您的个人信息，<b>如果未来业务发展需要从第三方间接获取您的个人信息，我们会在获取前向您明示个人信息的来源、类型及使用范围。如果游盟开展业务需要进行的个人信息处理活动超出了您向第三方授权同意的处理范围，我们将在处理您的个人信息前征得您的明示同意。</b>我们亦将严格遵守相关法律规定，要求第三方保障其提供的信息的合法性。我们对涉及用户信息使用的SDK相关情况进行了逐项列举。<br />
          <br />
          <b> (1) 友盟SDK </b> <br />
          友盟同欣（北京）科技有限公司 <br />
          共享个人信息的目的：统计分析、推送消息 <br />
          共享个人信息的类型：设备标识信息、应用列表、mac地址 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。<br />
          更多详情请见友盟同欣（北京）科技有限公司隐私政策链接:
          <a
            href="https://www.umeng.com/page/policy"
            style="font-size: 15px; color: #3994ffff"
            >https://www.umeng.com/page/policy</a
          >
          <br />
          保存期限：玩呗的使用期间、合理必要期限 <br />
          联系方式：Umeng_Legal@service.umeng.com（如果您需撤回对个人信息处理的同意，可以将相关要求通过该邮箱发送至友盟同欣（北京）科技有限公司。）<br />
          <br />
          <b> (2) QQ开放平台登录分享SDK </b> <br />
          深圳市腾讯计算机系统有限公司 <br />
          共享个人信息的目的：接入QQ开放平台SDK，支持QQ授权登录和QQ分享 <br />
          共享个人信息的类型：设备标识信息、mac地址 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。<br />
          更多详情请见深圳腾讯计算机系统有限公司《QQ隐私保护指引》:
          <a
            href="https://ti.qq.com/agreement/qqface.html?appname=mqq_2019"
            style="font-size: 15px; color: #3994ffff"
            >https://ti.qq.com/agreement/qqface.html?appname=mqq_2019</a
          >
          与《腾讯隐私政策》:
          <a
            href="https://privacy.tencent.com/"
            style="font-size: 15px; color: #3994ffff"
            >https://privacy.tencent.com/</a
          >
          <br />
          保存期限：玩呗的使用期间、合理必要期限 <br />
          联系方式：Dataprivacy@tencent.com（如果您需撤回对个人信息处理的同意，可以将相关要求通过该邮箱发送至深圳市腾讯计算机系统有限公司。）<br />
          <br />
          <b> (3）微信开放平台SDK </b> <br />
          深圳市腾讯计算机系统有限公司 <br />
          共享个人信息的目的：接入微信开放平台SDK，支持微信授权登录、微信分享和微信支付
          <br />
          共享个人信息的类型：设备标识信息、mac地址 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。<br />
          更多详情请见深圳腾讯计算机系统有限公司《微信隐私保护指引》:
          <a
            href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy"
            style="font-size: 15px; color: #3994ffff"
            >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</a
          >
          <br />
          保存期限：玩呗的使用期间、合理必要期限 <br />
          联系方式：Dataprivacy@tencent.com（如果您需撤回对个人信息处理的同意，可以将相关要求通过该邮箱发送至深圳市腾讯计算机系统有限公司。）<br />
          <br />
          <b> (4) 腾讯buglySDK </b> <br />
          深圳市腾讯计算机系统有限公司 <br />
          使用目的：Bug排查、版本升级 <br />
          共享个人信息的目的：未共享数据 <br />
          数据类型：未共享数据 <br />
          详情请见《腾讯Bugly用户协议》:
          <a
            href="https://bugly.qq.com/v2/contract"
            style="font-size: 15px; color: #3994ffff"
            >https://bugly.qq.com/v2/contract</a
          >
          <br />
          <br />
          <b> (5) 小米推送SDK </b> <br />
          北京小米科技有限责任公司 <br />
          共享个人信息的目的：推送消息 <br />
          共享个人信息的类型：设备标识信息 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。<br />
          更多详情请见小米隐私政策:
          <a
            href="https://www.mi.com/about/new-privacy"
            style="font-size: 15px; color: #3994ffff"
            >https://www.mi.com/about/new-privacy</a
          >
          <br />
          联系方式：mailto:privacy@xiaomi.com（如果您需撤回对个人信息处理的同意，可以将相关要求通过该邮箱发送至北京小米科技有限责任公司。）
          <br />
          <br />
          <b> (6) 华为推送SDK </b> <br />
          华为技术有限公司 <br />
          共享个人信息的目的：推送消息 <br />
          共享个人信息的类型：设备标识信息 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。更多详情请见华为隐私政策：<a
            href="https://www.huawei.com/cn/privacy-policy"
            style="font-size: 15px; color: #3994ffff"
            >https://www.huawei.com/cn/privacy-policy</a
          >
          <br />
          保存期限: 玩呗的使用期间、合理必要期限 <br />
          联系方式:
          <a
            href="https://www.huawei.com/cn/personal-data-reques"
            style="font-size: 15px; color: #3994ffff"
            >https://www.huawei.com/cn/personal-data-reques</a
          >（如果您需撤回对个人信息处理的同意，可以将相关要求通过网页发送至华为技术有限公司。）
          <br />
          <br />
          <b> (7) 穿山甲 </b> <br />
          北京巨量引擎网络技术有限公司 <br />
          共享个人信息的目的：提供第三方广告服务 <br />
          共享个人信息的类型：设备标识信息、mac地址 <br />
          个人信息的处理方式：去标识化、匿名化后传输、存储、共享等。
          更多详情请见穿山甲隐私政策: <br /><a
            href="https://www.pangle.cn/privacy"
            style="font-size: 15px; color: #3994ffff"
            >https://www.pangle.cn/privacy</a
          ><br />
          保存期限：玩呗的使用期间、合理必要期限 <br />
          联系方式：union_service@bytedance.com（如果您需撤回对个人信息处理的同意，可以将相关要求通过该邮箱发送至北京巨量引擎网络技术有限公司。）
          <br />

          <br /><br />
          <b>（八）解决您的问题</b> <br />
          您可以通过本款产品向我们反馈您使用本款产品中遇到的问题。如果您没有注册我们的账号，您需要向我们提交您的手机号码、邮箱或QQ号码以便我们能联系您并对您反馈的问题进行解答。如果您注册了我们的账号，您亦可以通过登录用户中心向我们反馈问题。在您使用客服或其他用户响应功能时，我们可能还会需要收集<b>您的如下个人敏感信息：联系方式（您与我们联系时使用的手机号码、邮箱或QQ号码或您向我们主动提供的其他联系方式）、您与我们的沟通信息（包括文字/图片形式）、与您需求相关联的其他必要信息。</b>我们会通过分析前段中提到的信息找出问题原因并努力解决问题。您在反馈意见中所提供的联系方式，只会用于我们的客服与您沟通与反馈，除征得用户明确同意和法律明确规定外，游盟不会向任何第三方提供您提供的联系信息。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供可能导致您无法使用我们的客服等用户响应机制。
          <br />
          <b>（九）改善我们的产品和服务</b> <br />
          我们可能会收集<b>经过MD5算法加密的国际移动设备身份码（IMEI）和网络设备地址（MAC），以及手机型号、手机系统版本号、系统编号、系统ID号、屏幕分辨率、上网类型、手机中软件的名称、版本号、包名、软件使用时间和频率、软件崩溃日志</b>用于统计我们产品的用户数量、分析产品的使用状况、排查崩溃原因、减少崩溃情况，以不断改进我们的产品。这些信息不涉及到您的个人身份等隐私信息。
          <br />
          <b>（十）为您的安全提供保障</b> <br />
          为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或游盟相关协议规则的情况。<b>我们可能使用或整合您的账户信息、设备信息、软件使用信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，</b>用于进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
          <br />
          <b>（十一）为您提供其他附加服务</b> <br />
          为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供上述信息，不会影响您使用玩呗的浏览、搜索等基本服务，但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：
          <br />
          <b>
            1. 基于相机/摄像头的附加服务：您可以使用该功能拍照上传图片。 <br />
            2.
            基于相册（图片库）的图片/视频访问及上传的附加服务：您可以使用该功能上传您的照片/图片，以实现发布照片/图片的功能、与其他用户进行照片/图片分享等功能。
            <br />
            您理解并同意，上述附加服务可能需要您在您的设备中开启您的摄像头（相机）、相册（图片库）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
          </b>
          <br />
          <b>（十二）其他</b> <br />
          如上文所述，<b
            >如果某一需要收集您的个人信息的功能或产品/服务未能在本《玩呗隐私政策》中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们将在收集和使用你的个人信息前，通过更新本《玩呗隐私政策》、页面提示、弹窗、消息通知、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您明示同意后收集和使用。
            <br />
            征得授权同意的例外</b
          >
          <br />
          根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br />
          1. 与国家安全、国防安全有关的；<br />
          2. 与公共安全、公共卫生、重大公共利益有关的；<br />
          3. 与犯罪侦查、起诉、审判和判决执行等有关的；<br />
          4.
          出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br />
          5. 所收集的个人信息是您自行向社会公众公开的；<br />
          6.
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
          7. 根据您的要求签订合同所必需的；<br />
          8.
          用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />
          9. 法律法规规定的其他情形。<br />
        </span>
        <br />
        <h4>三、我们如何存储您的个人信息</h4>
        <span>
          <b
            >（一）存储地域 <br />
            我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。</b
          ><br />
          <b>（二）存储期限 </b><br />
          我们仅在本政策所属目的的所必须期间和法律法规要求的时限内存储您的个人信息。在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。
          <br />
        </span>
        <br />
        <h4>四、我们如何共享、转让、公开披露您的个人信息</h4>
        <span>
          <b>（一）共享 </b><br />
          我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：
          <br />
          1.
          我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
          <br />
          2. 在获取您明确同意的情况下，我们会与其他方共享您的个人信息。<br />
          3.
          在我们的关联方向您或向我们提供服务的情况下，我们可能与我们的关联公司共享您的个人信息。但我们仅共享必要的个人信息，且关联方对您信息的处理受本隐私政策的约束。关联公司如要改变您个人信息的处理目的，将再次征求您的授权和同意。
          <br />
          4.
          除非获得您的明确授权和同意，否则我们不会与第三方广告商、应用程序开发者、开放平台或其他合作伙伴共享您的个人信息。我们可能会为这些合作伙伴提供汇总后的信息、匿名化后的信息或者其他不会识别到您个人身份的信息。例如，我们可能会告知应用程序开发者有多少人安装过其开发的应用程序。
          <br />
          5.
          仅为实现本隐私政策中声明的目的，我们可能会与我们的供应商、服务提供商、顾问或代理人共享您的个人信息，以提供更好的客户服务和用户体验。这些供应商、服务提供商、顾问、代理人可能为我们提供技术基础设施服务，分析我们服务的使用方式，衡量广告和服务的有效性，提供客户服务和支付服务，进行学术研究和调查，或提供法律、财务与技术顾问服务。
          <br />
          值得提醒的是，我们将在APP首次运行时通过弹窗等明显方式提示您阅读本《隐私政策》并取得您的授权，之后才初始化SDK进行信息采集。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
          <br />
          <b>（二）转让 </b><br />
          我们不会将您的个人信息转让给其他任何公司、组织和个人，但以下情形除外
          <br />
          1.
          随着公司业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。
          <br />
          2.
          在获得您明确同意的情形下转让，亦即获得您明确同意后，我们会向其他方转让我们已经获取的您的个人信息。
          <br />
          <b>（三）披露</b><br />
          我们仅会在以下情况下，公开披露您的个人信息： <br />
          1. 已经获得您明确同意。 <br />
          2.
          在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
          <br />
          3. 法律法规规定的其他情形。 <br />
        </span>
        <br />
        <h4>五、我们如何保护您的个人信息</h4>
        <span>
          我们已采取符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。<br />
          1.
          我们会对可识别的个人身份信息进行加密传输和存储，以确保数据的保密性。对于您手机设备标识符国际移动设备身份码（IMEI）、网络设备地址（MAC），我们在您的设备端会用MD5算法进行匿名化和加密，并仅收集和上传经过匿名化和加密后的标识符。
          <br />
          2.
          如果用户注册了游盟账号，我们可能通过对游盟用户的登录时间、IP、登录次数等信息进行分析，妥善做好风控管理。如有疑似非本人登录操作的情况，我们将通过短信提醒用户，避免游盟账户被非本人登录操作。对于您使用的手机与我们客户端之间的数据交换，我们采用了我们自定义的加密方案和https双重加密的方式进行传输，以确保数据传输的安全性。
          <br />
          3.
          若我们的应用采用了WebView技术，我们会对在WebView中使用的所有file协议进行URL校验且限制访问本应用敏感数据以及SDCard数据，避免在无特殊权限的情况下游盟产品的任意私有文件和敏感信息被恶意盗用，从而造成您的个人信息被泄露。
          <br />
          4.
          我们在服务端部署访问控制机制，对可能接触到您个人信息的工作人员采取最小够用授权原则，并定期核查访问人员名单和访问记录。
          <br />
          5.
          我们存储用户个人信息的服务器系统均为安全加固后的操作系统。我们会对服务器操作进行账户审计及监控。如果发现外部公告有安全问题的服务器操作系统，游盟会在第一时间会进行服务器安全升级，确保游盟所有服务器系统及应用安全。
          <br />
          6.
          我们为工作人员定期举办个人信息保护相关法律法规培训，以加强工作人员的用户个人隐私保护意识。
          <br />
          7.
          如果不幸发生我们的物理、技术或管理防护措施遭到破坏的事件，我们会及时启动应急预案，防止安全事件扩大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将要采取的措施等。
          <br />
        </span>
        <br />
        <h4>六、您管理个人信息的权利</h4>
        <span>
          在您使用玩呗期间，您可以通过以下方式访问并管理您的个人信息：<br />
          <br />
          <b>（一）访问和更正您的个人信息 </b><br />
          您可以通过游盟官网登录游盟用户中心查询和更正您的账户信息以及您提供的信息，包括密码、性别、年龄、生日、爱好、婚姻状况等信息。<br />
          <b>（二）删除您的个人信息 </b><br />
          以下情况发生时，您有权要求游盟删除您的个人信息： <br />
          1. 我们没有征求您的明确同意，收集了您的个人信息。 <br />
          2. 我们处理您的个人信息违反了法律法规要求。 <br />
          3. 我们违反了与您的约定来使用和处理您的个人信息。 <br />
          4. 您注销了游盟账号、卸载或者不再使用我们的产品（或服务）。 <br />
          5. 我们停止对您提供服务。<br />
          您可以通过本隐私政策第九节提供的方式联系我们要求删除您的个人信息，我们会在15个工作日内给予答复。当我们从服务器中删除您的个人信息后，我们可能不会立即从备份系统中删除相应的数据，但会在备份更新的时候删除这些信息。
          <br />
          <b>（三）注销您的个人帐号 </b><br />
          如果您选择注销游盟帐号，那么您的游盟帐号将不可被使用且相关账号信息将被删除，您将无法再通过游盟帐号登录游盟的服务（但不会影响您使用无需账号登录即可使用的服务和功能）。若您需要注销您的游盟帐号，请联系游盟的官方在线客服进行帐号注销操作。如您选择注销您的账号，则该账号下的全部资产（如有）将会全部即时删除，并无法恢复，请您慎重考虑是否删除账号。
          <br />
          当您注销帐号后，除法律法规要求我们保存相关信息的情况外，我们将停止为您提供相应的产品（或服务），并在15个工作日内删除或匿名化您的个人信息。
          <br />
          <b>（四）撤回您的同意或授权 </b><br />
          如您想更改相关功能的授权范围（包括：通讯录、相机、麦克风、消息推送，具体以产品实际获取的功能为准），您可以通过关闭设备功能而撤回对您个人信息收集和使用的同意。对于本款产品而言，您可以通过手机的设置功能关闭对我们收集您的GPS位置信息的权限。请您理解，每项业务功能需要一些基本的个人信息才能得以完成（见本隐私政策第一节），当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
          <br />
        </span>
        <br />
        <h4>七、未成年人个人信息保护</h4>
        <span>
          未成年人使用我们的产品与/或服务之前应首先取得其监护人的同意。如您为未成年人，在使用我们的产品与/或服务前，应在监护人监护、指导下共同阅读本《玩呗隐私政策》，且应在监护人明确同意和指导下使用我们的产品与/或服务、提交个人信息。我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律法规允许、监护人明确同意或保护您的权益所必要的情况下收集、使用或公开披露未成年人的个人信息；若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过“如何联系我们”章节中的联系方式与我们联系。如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
          <br />
        </span>
        <br />
        <h4>八、隐私政策的适用范围</h4>
        <span>
          本隐私政策仅适用于玩呗。游盟的其他产品和服务将使用相关产品或服务的隐私政策。特别需要说明的是，本隐私政策不适用以下情况：<br />
          1.
          将游盟产品（或服务）嵌入到第三方产品（或服务），第三方产品（或服务）收集的信息。<br />
          2.
          在游盟产品（或服务）中接入的第三方服务、广告或其他公司、组织或个人所收集的信息。<br />
        </span>
        <br />
        <h4>九、隐私政策的变更和修订</h4>
        <span>
          <b
            >我们鼓励您在每次使用我们的产品或服务时都查阅我们的《玩呗隐私政策》。</b
          >为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本《玩呗隐私政策》的条款，该类更新构成本《玩呗隐私政策》的一部分。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。
          对于本隐私政策的重大变更，我们还会提供显著的通知（例如，在软件改版或升级、或您重新登录时，以弹窗形式对您进行及时通知）。若您继续使用我们的服务，即表示您充分阅读、理解并同意受经修订的《玩呗隐私政策》的约束。为保障您的合法权益，我们建议您可以定期在我们平台的“设置-关于我们-用户隐私政策”中查看本政策。
          <br />
          本政策所指的重大变更包括但不限于： <br />
          1.
          我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          <br />
          2. 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
          <br />
          3. 个人信息共享、转让或公开披露的主要对象发生变化； <br />
          4. 您参与个人信息处理方面的权利及其行使方式发生重大变化； <br />
          5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
          <br />
          6. 个人信息安全影响评估报告表明存在高风险时。<br />
        </span>
        <br />
        <h4>十、如何联系我们</h4>
        <span>
          如果您对我们的隐私政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请通过以下方式与我们联系：
          <br />
          公司名称：广州游盟信息技术有限公司  <br />
          注册地址：广州市天河区中山大道西238号301房（部位：自编之十二） <br />
          常用办公地址：广东省广州市天河区中山大道西238号勤天大厦402室 <br />
          信息保护人负责人：<b>王硕 </b><br />
          信息保护负责人联系电话：<b>020-66837100 </b><br />
          联系邮箱：<b> guildunion@163.com </b><br />
          如有更多问题，请点此反馈 <br />
          在一般情况下，我们会在15个工作日内对您的请求予以答复。 <br />
        </span>
        <br />
        <div class="footer—company">
          <p>公司名称：广州游盟信息技术有限公司 </p>
          <p>生效日期：2021年6月1日</p>
        </div>
        <div style="height: 44px"></div>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'YbHomePolicy'
}
</script>

<style lang="scss" scoped>
</style>
