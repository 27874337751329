<template>
  <div>
    <aside>
      <div class="user-content">
        <h2 style="text-align: center">投诉指引</h2>
        <br />
        <p>
          玩呗作为网络服务提供者，为用户提供丰富的互联网信息服务。如果您是某一作品（包括作品整体或作品中包含的美术、文字介绍、音乐作品等部分内容）的著作权人和/或依法可以行使信息网络传播权的权利人，且您认为在本平台发布的内容侵犯了您的合法权益，您可依据本指引通知我们，我们将依法处理。您应对书面通知/投诉的真实性负责。
        </p>
        <br />
        <h4>一、通知流程</h4>
        <br />
        <p>
          权利人认为第三方在玩呗平台上提供的内容侵犯其合法权益，可向玩呗发出书面投诉通知书。
        </p>
        <p><b>您的通知文件应包含以下内容和材料：</b></p>
        <br />
        <span>
          1、权利人主体信息及证明材料；<br />
          1）个人投诉：权利人的真实姓名、联系电话及邮箱、联系地址、身份证（中国公民）或护照（外国公民）的照片或扫描件等。<br />
          2）单位投诉：权利人的名称、住所地、联系人、联系电话及邮箱、投诉通知（加盖公章）、营业执照（加盖公章）等。
          <br />
          3）代他人投诉：除上述1）和2）中要求的投诉人和权利人双方的主体身份信息和证明材料外，还需提供权利人签名或盖章的委托书。<br />
          2、要求删除或断开链接的作品、文字名称、作品编号、发布页截图、具体侵权内容截图（美术作品或文字作品侵权）及具体网络地址
          <br />
          1）被投诉内容的完整标题/名称/编号和发布页截图网络地址、所在位置描述等足以准确定位侵权内容的相关信息。
          <br />
          2）被投诉内容侵犯的权利类型（如：商标权、著作权等）。 <br />
          3）权利人需要明确要求断开链接的准确网络URL地址。 <br />
          3、构成侵权的初步证明材料 <br />
          1）权利人的权属材料,包括但不限于ICP备案证明、著作权登记证书、商标注册证书、专利权证书、作品首次公开发表或发行日期证明材料、经权威机构签发的作品创作时间戳、及创作手稿（如有能显示记录编辑时间的计算机储存记录）等能证明权利人拥有相关权利的有效权属证明；如为授权取得，权利人还需提供完整的授权文件等。
          <br />
          2）被投诉内容构成侵权的证明材料：包括但不限于被投诉内容构成对权利人著作权、商标权或专利权等权利侵权的有效证明材料（如发布时间比对、内容比对等）。<br />
          3）其他权利侵权投诉 <br />
          ① 指明被投诉信息中具体哪些内容涉嫌侵犯权利人的何种权益及相关法律规定。
          <br />
          ② 构成侵权的事实和详细理由说明。 <br />
          <b>4、权利人的保证</b>
          <p>
            <b
              >权利人应保证在通知书中的陈述和提供的相关材料皆是真实、有效和合法的，并保证承担和赔偿，因广州游盟信息技术有限公司 根据权利人的通知书而下架有关内容而给广州游盟信息技术有限公司 造成的任何损失，包括但不限于广州游盟信息技术有限公司 因向被投诉方或用户赔偿而产生的等损失及广州游盟信息技术有限公司 名誉、商誉损害等。</b
            >
          </p>
        </span>
        <br />
        <h4>二、反通知流程</h4>
        <br />
        <p>
          <b
            >玩呗根据权利人通知书删除相关链接或内容的，涉嫌侵权内容提供者(被投诉方）收到玩呗转送的通知书后，应按照以下的指引要求在五个工作日内向玩呗提交书面反通知书。</b
          >
          被投诉方认为其提供的作品并未侵犯他人权利的，可以要求恢复被删除的作品或被断开的作品链接。玩呗在收到反通知书后，可以恢复被移除的内容，且依法对该恢复行为不承担法律责任。
        </p>
        <span>
          反通知书的内容包括：<br />
          1、反通知人主体信息和相关证明材料 <br />
          1）反通知方是个人：真实姓名、联系电话及邮箱、联系地址、身份证（中国公民）或护照（外国公民）的照片或扫描件等。<br />
          2）反通知方是单位：权利人的名称、住所地、联系人、联系电话及邮箱、投诉通知（加盖公章）、营业执照（加盖公章）等。<br />
          3）反通知书需由权利人或其合法授权人亲笔签名，若为单位则需加盖单位公章。<br />
          2、要求恢复被删除或者被断开链接的作品或内容的准确名称和网络地址 <br />
          1）反通知内容的完整标题/名称和网络地址、所在位置描述等足以准确定位侵权内容的相关信息。<br />
          2）反通知人需要明确要求恢复链接的准确网络地址。 <br />
          3、不构成侵权的初步证明材料，包括但不限于ICP备案证明、著作权登记证书、商标注册证书、专利权证书、作品首次公开发表或发行日期证明材料、经权威机构签发的作品创作时间戳、及创作手稿等能证明权利人拥有相关权利的有效权属证明；如为授权取得，权利人还需提供完整的授权文件等。
          <br />
          （证明材料具体类型、内容可参考第1.3条“构成侵权的初步证明材料”）
        </span>
        <br />
        <span><b>4、反通知方的声明和保证</b></span>
        <p>
          <b
            >反通知人应保证反通知书中的陈述及提供的材料，均为真实、合法、有效，并保证承担和赔偿玩呗因根据被投诉方的反通知书，而在玩呗上保留或删除、断开被投诉的游戏或相关内容而给玩呗造成的损失，包括但不限于玩呗因向权利人或用户赔偿而产生的损失等及玩呗名誉、商誉损害等。反通知书及相关证明材料准备时需注意的事项以及投寄地址详见下文注意事项中的说明。</b
          >
        </p>
        <br />
        <h4>三、玩呗的处理</h4>
        <br />
        <p>
          玩呗作为中立的平台服务者，收到权利人符合本指引要求的通知书后，会将权利人的通知书转送给被投诉方。
        </p>
        <h4>1、权利人投诉流程</h4>
        <span>
          1）玩呗收到权利人符合本指引要求的投诉通知后，会尽快依法对被投诉内容做删除或断开链接等处理。<br />
          <b
            >2）对不符合本指引要求的投诉通知，玩呗有权在权利人补正前暂不处理。</b
          >
          <br />
          3）对于侵犯权利人权益的投诉通知，玩呗在进行处理投诉的同时可依法将投诉通知转送服务对象。
          <br />
          4）如权利人发起的投诉只针对作品中的部分内容，而非针对作品整体投诉侵权的（如：游戏作品中使用的其中一个或数个图标、一段背景音乐或少部分文字描述侵权），在被投诉人提供已经清除内容后的更新作品及更新说明后，玩呗可以许可被投诉人重新发布更新后作品。
          <br />
        </span>
        <br />
        <h4>2、反通知流程</h4>
        <span>
          玩呗作为中立的平台服务者，会依法进行相关处理：<br />
          1）若被投诉方认可权利人的投诉，玩呗会尽快按照相关法规的规定进行处理；作品清除侵权内容及提供更新说明后，被投诉方可以申请重新上架。<br />
          2）若被投诉方不认可权利人的投诉 <br />
          <p>
            玩呗会将被投诉方提供的相关材料转送给权利人，若权利人对于被投诉方的意见及其提供的相关材料有异议的，玩呗建议权利人另行通过行政投诉、诉讼等方式直接和被投诉方解决相关问题。如果权利人有新的并可充分推翻被投诉方意见的证明材料的，也可向玩呗提供。
          </p>
        </span>
        <br />
        <h4>四、注意事项</h4>
        <br />
        <span>
          （1）本指引中的权利人，指拥有著作权、商标权、专利权等合法权益的原始所有人或经原始所有人合法授权的代理人，包括自然人、法人或其他组织等。
          <br />
          （2）为了确保投诉的真实性和有效性，权利人的书面通知书及其他相关证明材料，原则上应提供原件，不能提供原件的，应提供复印件（在复印件上应有权利人的签章），若材料涉外的，应按照法律的规定进行公证转递，并同时提供相应的公证转递材料。
          <br />
          （3）权利通知、反通知应将上述材料纸质版本材料扫描的电子版本发送到玩呗指定电子邮箱：<b
            >guildunion@163.com</b
          >
          <br />
          （4）若证明材料是在外国或港澳台地区形成的，应按照法律规定在所在国家或地区进行公证认证或其他法律要求的证明程序。
          <br />
          （5）若权利人已经对被投诉人提起行政或司法争议解决程序的，请将通知书和相关受理证明、提交给争议解决机构的证据一并提交玩呗，这将有利于对投诉的处理。<br />
        </span>
        <br />
        <p>
          本声明受中华人民共和国法律的约束并依据其解释。如出现纠纷，双方应友好协商，协商不成，任何一方可提请广州市天河区人民法院诉讼解决。在法律允许的最大范围内，本条款最终解释权归广州游盟信息技术有限公司 享有。
        </p>
        <div class="footer—accessory">
          <h4>附：填写的示范格式：</h4>
          <br />
          <ul>
            <li>
              <img :src="attachment" alt="" />
              <span
                ><a
                  href="./complainText/侵权投诉表.doc"
                  download="侵权投诉表.doc"
                  >侵权投诉表</a
                ></span
              >
            </li>
            <li>
              <img :src="attachment" alt="" />
              <span
                ><a href="./complainText/反通知书.doc" download="反通知书.doc"
                  >反通知书</a
                ></span
              >
            </li>
            <li>
              <img :src="attachment" alt="" />
              <span
                ><a
                  href="./complainText/授权委托书（投诉方）.docx"
                  download="授权委托书（投诉方）.docx"
                  >授权委托书（投诉方）</a
                ></span
              >
            </li>
            <li>
              <img :src="attachment" alt="" />
              <span
                ><a
                  href="./complainText/授权委托书（被投诉方）.docx"
                  download="授权委托书（被投诉方）.docx"
                  >授权委托书（被投诉方）</a
                ></span
              >
            </li>
            <li>
              <img :src="attachment" alt="" />
              <span
                ><a
                  href="./complainText/玩呗投诉指引.docx"
                  download="玩呗投诉指引.docx"
                  >玩呗投诉指引 </a
                ></span
              >
            </li>
          </ul>
          <div style="height: 44px"></div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import attachment from '@/assets/img/icon_attachment.png'

export default {
  name: 'YbHomeComplain',
  setup () {
    return {
      attachment
    }
  }
}
</script>

<style lang="scss" scoped>
.footer—accessory {
  margin-top: 24px;
  margin-left: 4px;
}

.footer—accessory ul li img {
  width: 12px;
  vertical-align: middle;
}

.footer—accessory ul li {
  margin-bottom: 5px;
}

.footer—accessory ul li span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3994ff;
}

.footer—accessory ul li span a {
  list-style: none;
  color: #3994ffff;
  outline-style: none;
  -moz-outline-style: none;
  text-decoration: underline;
}
</style>
